import React, { PureComponent } from "react";
import { Col, Row, Modal, ButtonToolbar } from "reactstrap";
import axios from "axios";
import Todos from "../../Partner/components/deleteEmployee";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Popover, Button } from "antd";
import { withRouter } from "react-router-dom";
import "antd/dist/antd.css";
import { NotificationManager } from "react-notifications";
import Badge from "reactstrap/lib/Badge";
import moment from "moment";
import { Input } from "antd";

const columns = [
  {
    field: "field_status",
    headerName: "Төлөв",
    width: 100,
    color: "blue",
    renderCell: (params) => {
      if (params.row.field_status == "Засах") {
        return (
          <Badge style={{ backgroundColor: "orange" }}>
            {params.row.field_status}
          </Badge>
        );
      } else if (params.row.field_status == "Устгах") {
        return (
          <Badge style={{ backgroundColor: "red" }}>
            {params.row.field_status}
          </Badge>
        );
      } else if (params.row.field_status == "Хүлээгдэж буй") {
        return (
          <Badge style={{ backgroundColor: "blue" }}>
            {params.row.field_status}
          </Badge>
        );
      }
    },
  },

  {
    field: "last_name",
    headerName: "Овог",
    width: 150,
    color: "blue",
    // renderCell: renderCellExpand
  },
  { field: "first_name", headerName: "Нэр", width: 170 },
  { field: "registration_number", headerName: "Регистрийн дугаар", width: 150 },
  { field: "email", headerName: "Имэйл", width: 190 },
  { field: "phone_number", headerName: "Утасны дугаар", width: 120 },
  {
    field: "ua_code",
    headerName: "UA Код",
    width: 240,
  },
  { field: "shop_name", headerName: "Дэлгүүрийн нэр", width: 180 },

  {
    field: "work_started_at",
    headerName: "Гэрээ хийсэн огноо",
    width: 120,
    type: "date",
    valueGetter: (params) => {
      let result = params.row.work_started_at;
      let wsa = moment(result).format("L");
      return wsa;
    },
  },

  {
    field: "employee_status",
    headerName: "Статус",
    width: 150,
    color: "blue",
    renderCell: (params) => {
      if (params.row.employee_status == 1) {
        return (
          <Badge style={{ backgroundColor: "green" }}>Ажиллаж байгаа</Badge>
        );
      } else if (params.row.employee_status == 3) {
        return <Badge style={{ backgroundColor: "yellow" }}>Чөлөөтэй</Badge>;
      } else if (params.row.employee_status == 2) {
        return (
          <Badge style={{ backgroundColor: "blue" }}>
            Хүүхэд асрах чөлөөтэй
          </Badge>
        );
      } else if (params.row.employee_status == 4) {
        return <Badge style={{ backgroundColor: "pink" }}>Гэрээт</Badge>;
      }
    },
  },
];
var rowsToKeep = [];

class table extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { todos: [], reject_description: "", modal: false };
    this.onChangeRejectDesc = this.onChangeRejectDesc.bind(this);
    this.reject = this.reject.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    axios
      .get("https://merchantloyalty.upoint.mn/backend/api/employee/application")
      .then((response) => {
        this.setState({ todos: response.data });
        console.log(response.data, "todos");
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  verify(e) {
    if (rowsToKeep.length > 0) {
      e.preventDefault();
      console.log(rowsToKeep, "rowstoKeep");
      axios
        .post(
          "https://merchantloyalty.upoint.mn/backend/api/employee/verify",
          rowsToKeep
        )
        .then((res) => {
          console.log(res.data);
          NotificationManager.success(res.data.message, "", 5000);
          // window.location.reload();
        });
    } else {
      NotificationManager.error("Баталгаажуулах ажилтнаа сонгоно уу", "", 5000);
    }
  }
  reject(e) {
    e.preventDefault();
    console.log("fsdafafasdfdafasfa", this.state);

    axios
      .post("https://merchantloyalty.upoint.mn/backend/api/employee/reject", {
        rowsToKeep,
        description: this.state.reject_description,
      })
      .then((res) => {
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
        window.location.reload();
      });
    this.toggle();
  }
  toggle() {
    if (rowsToKeep.length > 0) {
      this.setState((prevState) => ({ modal: !prevState.modal }));
    } else {
      NotificationManager.error("Буцаах ажилтнаа сонгоно уу", "", 5000);
    }
  }
  todoList() {
    return this.state.todos.map(function (currentTodo, i) {
      return <Todos todo={currentTodo} key={i} />;
    });
  }
  onChangeRejectDesc(e) {
    this.setState({
      reject_description: e.target.value,
    });
  }
  handleRowSelection = (e) => {
    rowsToKeep = e;
    console.log("selected ids: " + rowsToKeep);
  };

  render() {
    const { modal } = this.state;
    const { TextArea } = Input;

    return (
      <div>
        <Row style={{ marginLeft: "0%" }}>
          <Col md={1.5}>
            <button
              style={{
                width: "200px",
              }}
              className="btn"
              onClick={this.verify}
            >
              Баталгаажуулах
            </button>
          </Col>
          <Col>
            <button
              style={{
                // position: "absolute",
                width: "150px",
                // height: "56px",
                backgroundColor: "gray",
              }}
              className="btn"
              onClick={this.toggle}
            >
              <Modal isOpen={modal} toggle={this.toggle}>
                <div className="modal__header"></div>
                <div className="modal__body">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Та тайлбар оруулна уу
                    </span>
                    <div className="form__form-group-field">
                      <TextArea
                        rows={4}
                        value={this.state.reject_description}
                        onChange={this.onChangeRejectDesc}
                      />
                    </div>
                  </div>
                </div>
                <ButtonToolbar className="modal__footer">
                  <Button className="modal_ok" onClick={this.reject}>
                    Буцаах
                  </Button>{" "}
                  <Button className="modal_cancel" onClick={this.toggle}>
                    Болих
                  </Button>{" "}
                </ButtonToolbar>
              </Modal>
              Буцаах
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: "600px", width: "auto" }}>
              <DataGrid
                getRowId={(row) => row._id}
                rows={this.state.todos}
                columns={columns}
                rowsPerPageOptions={[30, 50, 100]}
                checkboxSelection
                onRowDoubleClick={(e) =>
                  this.props.history.push("/application/fullInfo/" + e.id)
                }
                onSelectionModelChange={this.handleRowSelection}
                disableColumnMenu
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(table);
