import { Table } from "antd";
import axios from "axios";
import React, { PureComponent } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Button, ButtonToolbar, Modal } from "reactstrap";
var rowsToKeep;
class DepTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deps: [],
      delete_id: "",
      modal: false,
    };
    this.toggle = this.toggle.bind(this);

    this.onDelete = this.onDelete.bind(this);
  }
  componentDidMount() {
    axios
      .get("https://merchantloyalty.upoint.mn/backend/api/department/")
      .then((response) => {
        console.log(response.data, "response");
        this.setState({ deps: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
    console.log(this.props, "props");
  }
  onDelete() {
    axios
      .delete(
        "https://merchantloyalty.upoint.mn/backend/api/department/delete/" +
          this.state.delete_id
      )
      .then((res) => {
        console.log(res.data);
        this.toggle();
        NotificationManager.success(res.data.message, "", 5000);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const columns = [
      { title: "Газар, хэлтэс", dataIndex: "department", key: "_id" },
      {
        title: "Байгууллага",
        dataIndex: "merchant_name",
        key: "_id",
      },

      {
        render: (text, record, index) => (
          <span
            className="table--delete"
            onClick={(e) => {
              this.setState({ delete_id: record._id });
              this.toggle();
            }}
          >
            Устгах
          </span>
        ),
      },
    ];
    return (
      <div style={{ marginLeft: "3%" }}>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <div className="modal__header"></div>
          <div className="modal__body"> Газар хэлтсийг устгах уу?</div>
          <ButtonToolbar className="modal__footer">
            <Button className="modal_ok" onClick={this.onDelete}>
              Тийм
            </Button>{" "}
            <Button className="modal_cancel" onClick={this.toggle}>
              Үгүй
            </Button>{" "}
          </ButtonToolbar>
        </Modal>
        <NotificationContainer />
        <Table
          rowClassName="table-row-light"
          dataSource={this.state.deps}
          columns={columns}
        ></Table>
      </div>
    );
  }
}

export default DepTable;
