import React, { PureComponent } from "react";
import { Col, Container, Row, Card, CardBody, Table } from "reactstrap";
import { reduxForm } from "redux-form";
import "../../scss/component/navbar.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import axios from "axios";
import Tables from "./components/table";

class Segment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.todoList = this.todoList.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ merchant_id: e.target.value });
  }
  componentDidMount() {
    axios
      .get("https://merchantloyalty.upoint.mn/backend/api/segment/")
      .then((response) => {
        this.setState({ segments: response.data });
        // console.log(segments);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  todoList() {
    return this.state.segments.map(function (currentTodo, i) {
      return <Tables segments={currentTodo} key={i} />;
    });
  }

  render() {
    return (
      <div className="content">
        <Container style={{ marginLeft: "0px" }}>
          <Row>
            <Col>
              <button className="btn">
                <Link to={"/pages/addSegment"} style={{ color: "white" }}>
                  + Сегмент үүсгэх
                </Link>
              </button>
            </Col>
            <NotificationContainer />
          </Row>
          <Row>
            <Card>
              <CardBody style={{ width: "1844px", heigth: "auto" }}>
                <Col>
                  <div>
                    <p
                      style={{
                        fontFamily: " 'Montserrat', sans-serif;', sans-serif",
                        fontSize: "28px",
                        color: "#057AC4",
                        fontWeight: 400,
                      }}
                    >
                      Сегментүүд
                    </p>
                    <hr />
                  </div>
                </Col>
                <div style={{ marginLeft: "3%" }}>
                  <Table
                    className="table--bordered table--head-accent"
                    responsive
                    hover
                  >
                    <thead>
                      <tr>
                        <th>Сегмент</th>
                        <th>Байгууллага</th>
                        <th>Газар, хэлтэс</th>
                        <th>Ажилд орсон огноо</th>
                        <th>Төрсөн өдөр</th>
                        <th>Оператор</th>
                        <th>Хүйс</th>
                        <th>Нөхцөлийн код</th>
                      </tr>
                    </thead>

                    <tbody>{this.todoList()}</tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}

export default reduxForm({
  form: "vertical_form", // a unique identifier for this form
})(withTranslation("common")(Segment));
