import React, { PureComponent } from "react";
import { Card, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import { NotificationManager } from "react-notifications";

class Zasah extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSignType = this.onSignType.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      username: "",
      email: "",
      merchant_id: "",
      role: "Бүртгэх эрх",
      updated_at: "",
    };
  }

  componentDidMount() {
    axios
      .get(
        "https://merchantloyalty.upoint.mn/backend/api/admin/" +
          this.props.match.params.id
      )
      .then((response) => {
        this.setState({
          username: response.data.username,
          email: response.data.email,
          merchant_id: response.data.merchant_id,
          role: response.data.role,
          updated_at: response.data.updated_at,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }
  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  handleChange(e) {
    this.setState({ merchant_id: e.target.value });
  }
  onSignType(e) {
    this.setState({ role: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const obj = {
      username: this.state.username,
      email: this.state.email,
      merchant_id: this.state.merchant_id,
      role: this.state.role,
      updated_at: moment().format(),
    };

    axios
      .post(
        "https://merchantloyalty.upoint.mn/backend/api/admin/update/" +
          this.props.match.params.id,
        obj
      )
      .then((res) => {
        this.props.history.push("/pages/burtgelerh");
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
      });
  }

  render() {
    return (
      <Col md={12} lg={12}>
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">Бүртгэл Засах</CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Байгууллагын нэр
                  </span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.merchant_id}
                      onChange={this.handleChange}
                      disabled
                    >
                      <option value="61801ad4d0e95c22dd2fdc64">
                        UPoint - той дэлгүүр
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Нэвтрэх нэр</span>
                  <div className="form__form-group-field">
                    <input
                      name="username"
                      component="input"
                      type="text"
                      placeholder="Нэвтрэх нэр"
                      value={this.state.username}
                      onChange={this.onChangeUsername}
                      disabled
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">И-мэйл хаяг</span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      disabled
                      component="input"
                      type="email"
                      placeholder="И-мэйл хаяг"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Бүртгэлийн эрх сонгох
                  </span>
                  <div className="form__form-group-field">
                    <select value={this.state.role} onChange={this.onSignType}>
                      <option value="hr">Бүртгэх эрх</option>
                      <option value="director">Баталгаажуулах эрх</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <input type="submit" value="Хадгалах" className="uusgeh" />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default Zasah;
