import React, { PureComponent } from "react";
import { Card, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import SegmentSelect from "./segmentSelect";
import { Input } from "antd";
import verified from "../../Prover/components/verified";

class Zasah extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeUaCode = this.onChangeUaCode.bind(this);
    this.onChangeShopName = this.onChangeShopName.bind(this);
    this.onChangeRegistrationNumber =
      this.onChangeRegistrationNumber.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeDepartment = this.onChangeDepartment.bind(this);
    this.onChangePosition = this.onChangePosition.bind(this);
    this.onChangeWorkStart = this.onChangeWorkStart.bind(this);
    this.onChangeEmpStatus = this.onChangeEmpStatus.bind(this);
    this.onChangeStatusChange = this.onChangeStatusChange.bind(this);
    this.onChangeRejectDesc = this.onChangeRejectDesc.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      last_name: "",
      first_name: "",
      registration_number: "",
      phone_number: "",
      employee_status: "",
      email: "",
      company_name: "",
      department: "",
      position: "",
      merchant_id: "",
      merchant_name: "",
      work_started_at: "",
      errors: {},
      departments: [],
      emp_status_change_date: "",
      department: "",
      verified_at: "",
      reject_description: "",
      ua_code: "",
      shop_name: "",
    };
  }
  componentWillMount() {
    axios
      .get("https://merchantloyalty.upoint.mn/backend/api/segment/company")
      .then((response) => {
        console.log(response.data, "default");
        this.setState({
          merchant_id: response.data.merchant._id,
          merchant_name: response.data.merchant.name_mn,
          departments: response.data.company,
        });
        console.log(response.data, "data");
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  componentDidMount() {
    axios
      .get(
        "https://merchantloyalty.upoint.mn/backend/api/employee/" +
          this.props.match.params.id
      )
      .then((response) => {
        console.log(response.data, "state");

        this.setState({
          last_name: response.data.last_name,
          first_name: response.data.first_name,
          registration_number: response.data.registration_number,
          phone_number: response.data.phone_number,
          email: response.data.email,
          merchant_id: response.data.merchant_id,
          department: response.data.department,
          position: response.data.position,
          work_started_at: response.data.work_started_at,
          employee_status: response.data.employee_status,
          emp_status_change_date: response.data.emp_status_change_date,
          reject_description: response.data.reject_description,
          created_at: response.data.created_at,
          verified_at: response.data.verified_at,
          shop_name: response.data.shop_name,
          ua_code: response.data.ua_code,
        });
        console.log(this.state, "newstate");
      });
  }
  onChangeLastName(e) {
    this.setState({
      last_name: e.target.value,
      // work_started_at: moment(this.state.work_started_at).format("L")
    });
    console.log(
      moment(this.state.work_started_at).format("YYYY-MM-DD"),
      "formta"
    );
  }
  onChangeUaCode(e) {
    this.setState({
      ua_code: e.target.value,
    });
  }
  onChangeShopName(e) {
    this.setState({
      shop_name: e.target.value,
    });
  }
  onChangeFirstName(e) {
    this.setState({
      first_name: e.target.value,
    });
  }
  onChangeEmpStatus(e) {
    this.setState({
      employee_status: e.target.value,
    });
  }
  onChangeRegistrationNumber(e) {
    this.setState({
      registration_number: e.target.value,
    });
  }
  onChangePhoneNumber(e) {
    this.setState({
      phone_number: e.target.value,
    });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangeCompanyName(e) {
    this.setState({
      merchant_id: e.target.value,
    });
  }
  onChangeDepartment(e) {
    this.setState({
      department: e.target.value,
    });
  }

  onChangePosition(e) {
    this.setState({
      position: e.target.value,
    });
  }
  onChangeRejectDesc(e) {
    this.setState({
      reject_description: e.target.value,
    });
  }

  onChangeWorkStart(e) {
    this.setState({ work_started_at: e.target.value });
  }
  validate() {
    let last_name = this.state.last_name;
    let first_name = this.state.first_name;
    let registration_number = this.state.registration_number;
    let phone_number = this.state.phone_number;
    let work_started_at = this.state.work_started_at;
    let ua_code = this.state.ua_code;
    let shop_name = this.state.shop_name;

    let errors = {};

    let isValid = true;

    if (ua_code === "") {
      isValid = false;

      errors["ua_code"] = "Заавал утга оруулна уу";
    }
    if (shop_name === "") {
      isValid = false;

      errors["shop_name"] = "Заавал утга оруулна уу";
    }

    if (!work_started_at) {
      isValid = false;

      errors["work_started_at"] = "Заавал утга оруулна уу";
    }
    if (!registration_number) {
      isValid = false;

      errors["registration_number"] = "Регистрийн дугаараа оруулна уу";
    } else {
      if (typeof registration_number !== "undefined") {
        var pattern = new RegExp(/^[А-ЯӨҮЁ]{2}(\d){8}$/gmu);

        if (!pattern.test(registration_number)) {
          isValid = false;

          errors["registration_number"] = "Регистрийн дугаараа зөв оруулна уу";
        }
      }
    }
    if (!last_name) {
      isValid = false;

      errors["last_name"] = "Овгоо оруулна уу";
    } else {
      if (typeof last_name !== "undefined") {
        var pattern = new RegExp(/^[А-Яа-яӨөҮүЁё-]{1,}$/gmu);

        if (!pattern.test(last_name)) {
          isValid = false;

          errors["last_name"] = "Кириллээр бичнэ үү";
        }
      }
    }
    if (!first_name) {
      isValid = false;

      errors["first_name"] = "Нэрээ оруулна уу";
    } else {
      if (typeof first_name !== "undefined") {
        var pattern = new RegExp(/^[А-Яа-яӨөҮүЁё-]{1,}$/gmu);

        if (!pattern.test(first_name)) {
          isValid = false;

          errors["first_name"] = "Кириллээр бичнэ үү";
        }
      }
    }
    if (!phone_number) {
      isValid = false;

      errors["phone_number"] = "Утасны дугаар оруулна уу";
    } else {
      if (typeof phone_number !== "undefined") {
        var pattern = new RegExp(/^(\+\d{3}(-){0,1}){0,1}([8-9]\d{7})$/gmu);

        if (!pattern.test(phone_number)) {
          isValid = false;

          errors["phone_number"] = "Утасны дугаараа зөв оруулна уу";
        }
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }
  todoList() {
    return this.state.departments.map(function (currentTodo, i) {
      return <SegmentSelect departments={currentTodo} key={i} />;
    });
  }
  onChangeStatusChange(e) {
    this.setState({ emp_status_change_date: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const obj = {
      last_name: this.state.last_name,
      first_name: this.state.first_name,
      registration_number: this.state.registration_number,
      phone_number: this.state.phone_number,
      email: this.state.email,
      merchant_id: this.state.merchant_id,
      // department: this.state.department,
      // position: this.state.position,
      employee_status: this.state.employee_status,
      work_started_at: this.state.work_started_at,
      // emp_status_change_date: this.state.emp_status_change_date,
      reject_description: this.state.reject_description,
      ua_code: this.state.ua_code,
      shop_name: this.state.shop_name,
    };
    if (this.validate()) {
      axios
        .post(
          "https://merchantloyalty.upoint.mn/backend/api/employee/update/" +
            this.props.match.params.id,
          obj
        )
        .then((res) => {
          this.props.history.push("/partner/list");
          console.log(res.data);
          NotificationManager.success(res.data.message, "", 5000);
        })
        .catch((error) => {
          NotificationManager.error(error.response.data.message, "", 5000);
        });
    }
  }

  render() {
    const { TextArea } = Input;

    return (
      <Col md={12} lg={12}>
        <NotificationContainer style={{ position: "absolute" }} />
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">Гараар бүртгэх</CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Овог</span>
                  <div className="form__form-group-field">
                    <input
                      name="last_name"
                      component="input"
                      type="text"
                      placeholder="Овог"
                      value={this.state.last_name}
                      onChange={this.onChangeLastName}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.last_name}
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Нэр</span>
                  <div className="form__form-group-field">
                    <input
                      name="first_name"
                      component="input"
                      type="text"
                      placeholder="Нэр"
                      value={this.state.first_name}
                      onChange={this.onChangeFirstName}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.first_name}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Регистрийн дугаар
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="registration_number"
                      component="input"
                      type="text"
                      placeholder="Регистрийн дугаар"
                      value={this.state.registration_number}
                      onChange={this.onChangeRegistrationNumber}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.registration_number}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Утасны дугаар</span>
                  <div className="form__form-group-field">
                    <input
                      name="phone_number"
                      component="input"
                      type="text"
                      placeholder="Утасны дугаар"
                      value={this.state.phone_number}
                      onChange={this.onChangePhoneNumber}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.phone_number}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">И-мэйл хаяг</span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      type="text"
                      placeholder="И-мэйл хаяг"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Ажилтны статус</span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.employee_status}
                      onChange={this.onChangeEmpStatus}
                      placeholder="Ажилтны статус"
                      required
                    >
                      <option value="" selected disabled>
                        Ажилтны статус сонгоно уу
                      </option>
                      <option value="1">Ажиллаж байгаа</option>
                      <option value="2">Хүүхэд асрах чөлөөтэй</option>
                      <option value="3">Чөлөөтэй</option>
                      <option value="4">Гэрээт</option>
                    </select>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Гэрээ хийсэн огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="work_started_at"
                      component="input"
                      type="date"
                      placeholder="Гэрээ хийсэн огноо"
                      value={moment(this.state.work_started_at).format(
                        "YYYY-MM-DD"
                      )}
                      // value={this.state.work_started_at}
                      onChange={this.onChangeWorkStart}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Бүртгэсэн огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="created_at"
                      component="input"
                      type="date"
                      disabled
                      placeholder="Бүртгэсэн огноо"
                      value={moment(this.state.created_at).format("YYYY-MM-DD")}
                      // value={this.state.work_started_at}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Баталгаажсан огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="verified_at"
                      component="input"
                      defaultValue=""
                      type="date"
                      disabled
                      placeholder="Баталгаажсан огноо"
                      value={moment(this.state.verified_at).format(
                        "YYYY-MM-DD"
                      )}
                      // value={this.state.work_started_at}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.work_started_at}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">UA Код</span>
                  <div className="form__form-group-field">
                    <input
                      name="ua_code"
                      component="input"
                      type="text"
                      placeholder="UA Код"
                      value={this.state.ua_code}
                      onChange={this.onChangeUaCode}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.ua_code}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Дэлгүүрийн нэр</span>
                  <div className="form__form-group-field">
                    <input
                      name="shop_name"
                      component="input"
                      type="text"
                      placeholder="Дэлгүүрийн нэр"
                      value={this.state.shop_name}
                      onChange={this.onChangeShopName}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.shop_name}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Тайлбар</span>
                  <div className="form__form-group-field">
                    <TextArea
                      rows={4}
                      value={this.state.reject_description}
                      onChange={this.onChangeRejectDesc}
                    />
                  </div>
                </div>
                <br />
                <div>
                  <input
                    type="submit"
                    value="Үүсгэх"
                    className="uusgeh"
                    style={{ marginLeft: "220px" }}
                  />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default Zasah;
