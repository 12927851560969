import React, { Component } from "react";
import { withRouter } from "react-router-dom";
class table extends Component {
  render() {
    return (
      <tr>
        <td>{this.props.segments.key_word}</td>
        <td>{this.props.segments.merchant_id}</td>
        <td>{this.props.segments.department}</td>
        <td>{this.props.segments.working_years}</td>
        <td>{this.props.segments.born_date}</td>
        <td>{this.props.segments.network}</td>
        <td>{this.props.segments.sex}</td>
        <td>{this.props.segments.ua_code}</td>
      </tr>
    );
  }
}
export default withRouter(table);
