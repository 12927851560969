import React, { PureComponent } from "react";
import { Col, CardBody, CardTitle, Card, Row } from "reactstrap";
import { reduxForm, Field } from "redux-form";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "react-notifications/lib/notifications.css";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

class addDepartment extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeDepartment = this.onChangeDepartment.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      merchant_id: "5bcd43443fe112b9d6160d3d",
      department: "",
      segments: [],
    };
  }
  // componentDidMount() {
  //   axios
  //     .get("https://merchantloyalty.upoint.mn/backend/api/segment/company")
  //     .then((response) => {
  //       this.setState({
  //         segments: response.data,
  //         segment: response.data[0].key_word,
  //       });
  //       console.log(response.data, "todos");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  onChangeCompanyName(e) {
    this.setState({
      merchant_id: e.target.value,
    });
  }
  onChangeDepartment(e) {
    this.setState({
      department: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    console.log(this.state.segment, "segments");

    const newDepartment = {
      merchant_id: this.state.merchant_id,
      department: this.state.department,
    };

    console.log(this.state, "state");
    axios
      .post(
        "https://merchantloyalty.upoint.mn/backend/api/department/add",
        newDepartment
      )
      .then((res) => {
        this.setState({
          company_name: "",
          department: "",
        });
        this.props.history.push("/pages/department");
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
      });
  }
  //   segmentList() {
  //     return this.state.segments.map(function (currentTodo, i) {
  //       return <SegmentSelect segments={currentTodo} key={i} />;
  //     });
  //   }

  render() {
    return (
      <Col md={12} lg={12}>
        <NotificationContainer style={{ position: "absolute" }} />
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">
              Компаний мэдээлэл үүсгэх
            </CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Байгууллагын нэр
                  </span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.merchant_id}
                      onChange={this.onChangeCompanyName}
                    >
                      <option value="5bcd43443fe112b9d6160d3d">Юнител</option>
                      <option value="5bd95078b92b980bb3f1fb91">
                        Юнайтэд аллианс
                      </option>
                      <option value="5cd404ce5f627d42a5786602">МТ</option>
                      <option value="5d19afa55f627d26cfca48d7">Монос</option>
                      <option value="5c01f71b6dc84937dd321d1b">
                        Тү Лэ Жүр
                      </option>
                      <option value="5c10bc165f627d320c0969c6">Юнивишн</option>
                      <option value="607412575f627d089681fb74">
                        Гэр интернэт
                      </option>
                      <option value="5d142d215f627d62c382f155">Ддэш</option>
                      <option value="5c009abf5f627d2b147a6ad1">
                        Кафе Бэнэ
                      </option>
                      <option value="5ebb942e5f627d4e1e87d4ca">
                        Делүкс кар вош
                      </option>
                      <option value="5faa59d15f627d4e1aeab8f2">
                        Альфабүүк
                      </option>
                      <option value="6007b6bc5f627d4f6441a4f8">Инаяа</option>
                    </select>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Газар хэлтэс</span>
                  <div className="form__form-group-field">
                    <input
                      name="intro"
                      component="input"
                      type="text"
                      placeholder="Газар хэлтэс"
                      value={this.state.department}
                      onChange={this.onChangeDepartment}
                    />
                  </div>
                </div>

                <div>
                  <input type="submit" value="Үүсгэх" className="uusgeh" />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
// export default reduxForm({
//   form: "horizontal_form", // a unique identifier for this form
// })(withTranslation("common")(addEmployee));
export default reduxForm({
  form: "horizontal_form", // a unique identifier for this form
})(withTranslation("common")(addDepartment));
