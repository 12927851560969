import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { Badge, Button, ButtonToolbar, Modal } from "reactstrap";
import moment from "moment";
class table extends Component {
  constructor(props) {
    super(props);
    this.deleteStudent = this.deleteStudent.bind(this);
    this.unBlock = this.unBlock.bind(this);
    this.state = {
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
    console.log(this.props, "props");
  }
  deleteStudent() {
    axios
      .delete(
        "https://merchantloyalty.upoint.mn/backend/api/admin/delete/" +
          this.props.hrs._id
      )
      .then((res) => {
        console.log(res.data);
        // this.props.history.push("/pages/burtgelerh");
        NotificationManager.success(res.data.message, "", 5000);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
    this.toggle();
  }
  unBlock(e) {
    e.preventDefault();
    axios
      .post(
        "https://merchantloyalty.upoint.mn/backend/api/admin/unblock/" +
          this.props.hrs._id
      )
      .then((res) => {
        this.props.history.push("/pages/burtgelerh");
        console.log(res.data);
        NotificationManager.success(res.data.message, "", 5000);
      });
  }

  render() {
    const { modal } = this.state;
    return (
      <tr>
        <td>{this.props.hrs.username}</td>
        <td>{this.props.hrs.email}</td>
        <td>{moment(this.props.hrs.created_at).format("L")}</td>
        {/* <td>
              <Link to={"/edit/" + props.todo._id}>Edit</Link>
            </td> */}
        <td style={{ width: "100px" }}>
          <Badge
            color="dark"
            onClick={this.unBlock}
            to={"/pages/adminBurtgel/edit/" + this.props.hrs._id}
            style={{ color: "white" }}
          >
            Unblock
          </Badge>
          <NotificationContainer />
        </td>
        <td style={{ width: "100px" }}>
          <Badge color="warning">
            <Link
              to={"/pages/adminBurtgel/edit/" + this.props.hrs._id}
              style={{ color: "white" }}
            >
              Засах
            </Link>
          </Badge>
        </td>
        <td style={{ width: "100px" }}>
          <Badge color="danger" onClick={this.toggle}>
            Устгах
          </Badge>
          <Modal isOpen={modal} toggle={this.toggle}>
            <div className="modal__header"></div>
            <div className="modal__body">
              {" "}
              Ажилтан {this.props.hrs.username} -г устгах уу?
            </div>
            <ButtonToolbar className="modal__footer">
              <Button className="modal_ok" onClick={this.deleteStudent}>
                Тийм
              </Button>{" "}
              <Button className="modal_cancel" onClick={this.toggle}>
                Үгүй
              </Button>{" "}
            </ButtonToolbar>
          </Modal>
        </td>
      </tr>
    );
  }
}
export default withRouter(table);
