import React, { PureComponent } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { reduxForm } from "redux-form";
import "../../scss/component/navbar.scss";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import CardTitle from "reactstrap/lib/CardTitle";
import DepTable from "./components/ExampleCard";
// import Table from "./components/table";
// import SegmentSelect from "./components/segmentSelect";

class Company extends PureComponent {
  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="content">
        <Container style={{ marginLeft: "0px" }}>
          <Row>
            <Col md={1.5}>
              <button className="btn">
                <Link to={"/pages/addDepartment"} style={{ color: "white" }}>
                  + Газар, хэлтэс үүсгэх
                </Link>
              </button>
            </Col>

            <NotificationContainer />
          </Row>
          <Row>
            <Card>
              <CardBody style={{ height: "auto", width: "auto" }}>
                <Col>
                  <CardTitle className="card__title">Газар хэлтэс</CardTitle>
                  <hr />
                  <DepTable />
                </Col>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}

export default reduxForm({
  form: "vertical_form", // a unique identifier for this form
})(withTranslation("common")(Company));
