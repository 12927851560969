import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { authService } from "../../../services/auth.service";
import EyeIcon from "mdi-react/EyeIcon";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

class LogInForm extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    // const credentials = {
    //   username: this.state.username,
    //   password: this.state.password,
    //   client_id: "9n8823WHwxvf1I",
    // };
    authService
      .login(this.state.username, this.state.password)
      .then((res) => {
        this.props.history.push(res.data.views[0]);
        NotificationManager.success("Амжилттай!", "", 5000);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error(err.message, "", 5000);
      });
    // axios
    //   .post("https://auth.upoint.mn/internal/login", credentials)
    //   .then((res) => {
    //     this.setState({
    //       username: this.state.username,
    //       password: this.state.password,
    //     });
    //     if (!res.data.error) {
    //       this.props.history.push(res.data.views[0]);
    //       localStorage.setItem("access_token", res.data.access_token);
    //       localStorage.setItem("view", res.data.views[0]);
    //       NotificationManager.success("Амжилттай!", "", 5000);
    //     } else {
    //       NotificationManager.error(res.data.error_description, "", 5000);
    //     }
    //   });
  }

  render() {
    const { showPassword } = this.state;

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <NotificationContainer style={{ position: "absolute" }} />
        <div className="form__form-group">
          <span className="form__form-group-label">Нэвтрэх нэр</span>
          <div className="form__form-group-field">
            <Field
              name="username"
              component="input"
              type="text"
              placeholder="Нэвтрэх нэр"
              value={this.state.username}
              onChange={this.onChangeUsername}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Нууц үг</span>
          <div className="form__form-group-field">
            <Field
              name="password"
              component="input"
              type="password"
              type={showPassword ? "text" : "password"}
              placeholder="Нууц үг"
              value={this.state.password}
              onChange={this.onChangePassword}
            />
            <button
              type="button"
              className={`form__form-group-button${
                showPassword ? " active" : ""
              }`}
              onClick={(e) => this.showPassword(e)}
            >
              <EyeIcon />
            </button>
          </div>
        </div>

        <button
          className="btn account__btn account__btn--small"
          type="submit"
          value="Нэвтрэх"
        >
          Нэвтрэх
        </button>
        <Link
          className="btn btn-outline-primary account__btn account__btn--small"
          to="/forgot_password"
        >
          Нууц үг мартсан
        </Link>
      </form>
    );
  }
}

export default reduxForm({
  form: "log_in_form",
})(withRouter(LogInForm));
