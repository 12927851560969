import { message, Upload, Alert } from "antd";
import axios from "axios";
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { reduxForm } from "redux-form";
import XLSX from "xlsx";
import "../../scss/component/navbar.scss";
import Table from "./components/table";

class Partner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: [],
      modal: false,
      last_name: "",
      merchant_id: "61801ad4d0e95c22dd2fdc64",
      employees: [],
      rn: "",
      phone: "",
      name: "",
      employee_status: "",
      field_status: "",
    };
    this.handleFile = this.handleFile.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeFieldStatus = this.onChangeFieldStatus.bind(this);
    this.onChangeEmpStatus = this.onChangeEmpStatus.bind(this);
    this.onChangeRN = this.onChangeRN.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    // this.downloadFile = this.downloadFile.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  }
  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
    console.log(this.props, "props");
  }
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info, "lets see file");
    }
    if (status === "done") {
      this.setState({ file: info.fileList[0] });
      console.log(this.state.file, "Filelist ");
      message.success(`${info.file.name} Амжилттай хуулагдлаа.`);
    } else if (info.file === "error") {
      message.error(`${info.file.name} Амжилтгүй.`);
    }
    // console.log(this.state.file, "state file ");
  }
  componentDidMount() {
    // axios
    //   .get("https://merchantloyalty.upoint.mn/backend/api/segment/company")
    //   .then((response) => {
    //     console.log("ASDSAD", response.data.message);
    //     this.setState({
    //       merchant_id: response.data.merchant._id,
    //     });
    //     console.log("data xx ", this.state.merchant_id);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // axios
    //   .get("https://merchantloyalty.upoint.mn/backend/api/employee/")
    //   .then((response) => {
    //     this.setState({ employees: response.data });
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    axios
      .get("https://merchantloyalty.upoint.mn/backend/api/employee/", {
        params: {
          name: this.state.name,
          phone: this.state.phone,
          rn: this.state.rn,
          field_status: this.state.field_status,
        },
      })
      .then((response) => {
        this.setState({ employees: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleFile() {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */

      let resultList = [];
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      console.log("wss", ws);
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { defval: "" });
      const mapping = [
        "last_name",
        "first_name",
        "registration_number",
        "phone_number",
        "email",
        "employee_status",
        "shop_name",
        "ua_code",
        "work_started_at",
      ];

      console.log("fetched from excel", data);

      data.forEach((row) => {
        console.log(row, "rowni genee");
        const rowData = {};
        Object.values(row).forEach((columnValue, index) => {
          // console.log("yah vee", row[0]);
          rowData[mapping[index]] = columnValue;
        });
        console.log("-->", rowData);
        rowData["merchant_id"] = this.state.merchant_id;
        console.log("log in da houz", rowData["merchant_id"]);

        // var h_address = rowData.h_addrress;
        // console.log()
        // var work_started_at = rowData["work_started_at"];
        rowData["work_started_at"] = Date();
        console.log("aaa", rowData);
        resultList.push(rowData);
      });
      // console.log("filled data", (resultList = data));
      // resultList = data[0];

      this.toggle();
      /* Update state */
      // this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
      //   console.log(JSON.stringify(resultList, null, 4));
      // });

      axios
        .post(
          "https://merchantloyalty.upoint.mn/backend/api/employee/addMany",
          resultList
        )
        .then((res) => {
          // this.props.history.push("/partner/list");
          // console.log(res.data);
          if (res.data.code !== 0) {
            throw new Error(res.data.message);
          }

          NotificationManager.success(res.data.message, "Success", 5000);
        })
        .catch(function (error) {
          if (error && error.response) {
            NotificationManager.error(
              error.response.data.message,
              "Response Error message",
              5000
            );
          } else {
            NotificationManager.error(error.message, "Error message", 5000);
          }
        });
    };
    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }
  // downloadFile() {
  //   // axios.get("api.employeeloyalty.upoint.mn/getfile").then((resp) => {
  //   //   download(resp.data, fileName);
  //   //   console.log(resp, "restp");

  //   });
  // }
  onSearch() {
    axios
      .get("https://merchantloyalty.upoint.mn/backend/api/employee/", {
        params: {
          name: this.state.name,
          phone: this.state.phone,
          rn: this.state.rn,
          field_status: this.state.field_status,
        },
      })
      .then((response) => {
        this.setState({ employees: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  onChangeName(e) {
    this.setState({ name: e.target.value });
  }
  onChangeRN(e) {
    this.setState({ rn: e.target.value });
  }
  onChangePhone(e) {
    this.setState({ phone: e.target.value });
  }
  onChangeFieldStatus(e) {
    this.setState({ field_status: e.target.value });
  }
  onChangeEmpStatus(e) {
    this.setState({ employee_status: e.target.value });
  }

  render() {
    const { handleSubmit } = this.props;
    const { modal } = this.state;
    const { employees, phone, rn, name } = this.state;
    const upload = {
      name: "file",
      multiple: true,
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange(info) {
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info, "lets see file");
        }
        if (status === "done") {
          // this.setState({ file: info.fileList[0] });
          console.log(info.file, "Filelist ");
          message.success(`${info.file.name} Амжилттай хуулагдлаа.`);
        } else if (info.file === "error") {
          message.error(`${info.file.name} Амжилтгүй.`);
        }
        // console.log(this.state.file, "state file ");
      },
    };

    return (
      <div className="content">
        <Container>
          <Row>
            <Col md={1.5}>
              <button className="btn">
                <Link to={"/partner/addEmployee"} style={{ color: "white" }}>
                  + Гараар бүртгэх
                </Link>
              </button>
            </Col>

            <Col md={9}>
              <button
                className="btn"
                onClick={this.toggle}
                style={{ color: "white" }}
              >
                <Modal isOpen={modal} toggle={this.toggle}>
                  <div className="modal__header"></div>
                  <div className="modal__body">
                    {/* <Dragger {...upload}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Энд дарж эсвэл зөөж хуулна уу
                      </p>
                      <p className="ant-upload-hint">
                        Зөвхөн .xlsx өргөтгөлтэй файл дэмжинэ
                      </p>
                    </Dragger> */}
                    <input
                      type="file"
                      className="form-control"
                      id="file"
                      onChange={this.handleChange}
                    />
                  </div>
                  <ButtonToolbar className="modal__footer">
                    <Button className="modal_ok" onClick={this.handleFile}>
                      Бүртгэх
                    </Button>{" "}
                    <Button className="modal_cancel" onClick={this.toggle}>
                      Үгүй
                    </Button>{" "}
                  </ButtonToolbar>
                </Modal>
                + Excel файлаар бүртгэх
              </button>
            </Col>
            <Col md={1.5}>
              <button
                style={{
                  // position: "absolute",
                  // marginLeft: "170px",
                  backgroundColor: "#289E57",
                }}
                className="btn"
                // onClick={this.downloadFile}
              >
                <a
                  href="https://merchantloyalty.upoint.mn/backend/api/getFile"
                  download
                  target="_blank"
                  style={{ color: "white" }}
                >
                  + Excel файл татах
                </a>
              </button>
            </Col>
            <NotificationContainer />
          </Row>
          <Row>
            <Card>
              <CardBody style={{ height: "auto", width: "auto" }}>
                <Row style={{ marginLeft: "10px" }}>
                  {/* <form className="form" onSubmit={handleSubmit}> */}
                  <div className="form">
                    <Col>
                      <div className="form__form-group">
                        {/* <span className="form__form-group-label">Нэр</span> */}
                        <div className="form__form-group-field">
                          <input
                            name="name"
                            component="input"
                            type="text"
                            placeholder="Нэр"
                            value={this.state.name}
                            onChange={this.onChangeName}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        {/* <span className="form__form-group-label">
                          Регистрийн дугаар
                        </span> */}
                        <div className="form__form-group-field">
                          <input
                            name="registration_number"
                            component="input"
                            type="text"
                            placeholder="Регистрийн дугаар"
                            value={this.state.rn}
                            onChange={this.onChangeRN}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        {/* <span className="form__form-group-label">
                          Утасны дугаар{" "}
                        </span> */}
                        <div className="form__form-group-field">
                          <input
                            name="phone_number"
                            component="input"
                            type="text"
                            placeholder="Утасны дугаар"
                            value={this.state.phone}
                            onChange={this.onChangePhone}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <select
                            value={this.state.field_status}
                            onChange={this.onChangeFieldStatus}
                            placeholder="Төлөв"
                            // className="select-index"
                            style={{
                              height: "32px",
                              borderColor: "#f4f7f8",
                              boxShadow: "none",
                              color: "gray",
                            }}
                          >
                            <option value="" selected>
                              Төлөв
                            </option>
                            <option value="Шинэ">Шинэ</option>
                            <option value="Буцаагдсан">Буцаагдсан</option>
                            <option value="Хүлээгдэж буй">Хүлээгдэж буй</option>
                            <option value="Устгах">Устгах</option>
                          </select>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <button
                        className="btn"
                        type="submit"
                        onClick={this.onSearch}
                      >
                        Хайх
                      </button>
                    </Col>
                  </div>

                  {/* </form> */}
                </Row>
                <Row>
                  <Table
                    employees={employees}
                    phone={phone}
                    rn={rn}
                    name={name}
                  />
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}

export default reduxForm({
  form: "vertical_form", // a unique identifier for this form
})(withTranslation("common")(Partner));
