import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Collapse, Navbar, Nav, NavItem, NavLink } from "reactstrap";
import { authService } from '../../../services/auth.service'

class TopbarPartner extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  // logOut() {
  //   localStorage.clear()
  // }
  render() {
    return (

      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <Link className="topbar__logo" to="/partner/list" />
          </div>
          <div className="navbar">
            <Navbar light expand="md" variant="light">
              <Nav navbar>
                <NavItem>
                  <NavLink activeClassName="navbar__link--active"
                    className="navbar__link">
                    <Link to={"/partner/news"} style={{ color: "white" }} active>
                      МЭДЭЭ, МЭДЭЭЛЭЛ
                      </Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={"/partner/list"}>
                    <Link
                      to={"/partner/list"}
                      style={{ color: "white", marginLeft: "50px" }}
                      activeClassName="navbar__link--active"
                      className="navbar__link"
                    >
                      ХҮСЭЛТИЙН БҮРТГЭЛ
                      </Link>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link
                      to={"/partner/signed_employee"}
                      style={{ color: "white", marginLeft: "50px" }}
                    >
                      БҮРТГЭЛТЭЙ АЖИЛТАН
                      </Link>
                  </NavLink>
                </NavItem>
                <div className="topbar__right-partner">
                  <NavItem>
                    <NavLink
                      href="/change_password"
                      style={{ color: "white" }}
                    >
                      НУУЦ ҮГ СОЛИХ
                      </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      onClick={authService.logout}
                      href="/"
                      style={{
                        backgroundColor: "#40B0EF",
                        color: "white",
                        border: "none",
                        borderRadius: "6px",
                        marginLeft: "50px",
                        width: "70px",
                      }}
                    >
                      ГАРАХ
                      </NavLink>
                  </NavItem>
                </div>
              </Nav>
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}

export default TopbarPartner;
