import React from "react";

import LogInForm from "./components/LogInForm";
import logo from "../../shared/img/logo/login-logo.png";

const LogIn = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account--photo">
          <img src={logo} alt="logo" style={{ width: "100px" }}></img>
        </div>
        <LogInForm onSubmit />
      </div>
    </div>
  </div>
);

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
