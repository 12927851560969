import React, { PureComponent } from "react";
import { Col, CardBody, CardTitle, Card } from "reactstrap";
import { reduxForm } from "redux-form";
import axios from "axios";
import { withTranslation } from "react-i18next";
import moment from "moment";
import "react-notifications/lib/notifications.css";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
class Burtgeh extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSignType = this.onSignType.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      username: "",
      email: "",
      company_name: "61801ad4d0e95c22dd2fdc64",
      sign_type: "hr",
    };
  }
  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  handleChange(e) {
    this.setState({ company_name: e.target.value });
  }
  onSignType(e) {
    this.setState({ sign_type: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const registrationData = {
      client_id: "9n8823WHwxvf1I",

      merchant_id: this.state.company_name,
      username: this.state.username,
      email: this.state.email,
      role: this.state.sign_type,
    };

    if (this.state.username.length > 0 && this.state.email.length > 0) {
      axios
        .post(
          "https://merchantloyalty.upoint.mn/backend/api/admin/add",
          registrationData
        )
        .then((res) => {
          this.setState({
            username: "",
            email: "",
            company_name: "",
            sign_type: "",
          });
          console.log(res.data, "message");

          if (!res.data.error) {
            this.props.history.push("/pages/burtgelerh");
            console.log(res.data.message, "message");
            NotificationManager.success(res.data.message, "", 5000);
          } else {
            // NotificationManager.error(res.data.error_description, "", 5000);
            console.log(res.message, "message");
          }
        })
        .catch(function (error) {
          NotificationManager.error(error.response.data.message, "", 5000);
        });
    } else {
      NotificationManager.error(
        "Нэвтрэх нэр эсвэл имэйл хоосон утгатай байна!",
        "",
        5000
      );
    }
  }

  // <button className="btn" onClick={this.toggle}>
  //   + Бүртгэлийн эрх үүсгэх
  // </button>

  render() {
    return (
      <Col md={12} lg={12}>
        <NotificationContainer style={{ position: "absolute" }} />
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">Бүртгэлийн эрх үүсгэх</CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Байгууллагын нэр
                  </span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.company_name}
                      onChange={this.handleChange}
                    >
                      <option value="61801ad4d0e95c22dd2fdc64">
                        UPoint - той дэлгүүр
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Нэвтрэх нэр</span>
                  <div className="form__form-group-field">
                    <input
                      name="username"
                      component="input"
                      type="text"
                      placeholder="Нэвтрэх нэр"
                      value={this.state.username}
                      onChange={this.onChangeUsername}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">И-мэйл хаяг</span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      component="input"
                      type="email"
                      placeholder="И-мэйл хаяг"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Бүртгэлийн эрх сонгох
                  </span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.sign_type}
                      onChange={this.onSignType}
                    >
                      <option value="hr">Бүртгэх эрх</option>
                      <option value="director">Баталгаажуулах эрх</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <input type="submit" value="Үүсгэх" className="uusgeh" />
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default reduxForm({
  form: "horizontal_form", // a unique identifier for this form
})(withTranslation("common")(Burtgeh));
