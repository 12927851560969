import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Topbar from "./topbar/TopbarPartner";

class LayoutPartner extends Component {
  render() {
    return (
      <div>
        <Topbar />
      </div>
    );
  }
}

export default withRouter(
  connect((state) => ({
    sidebar: state.sidebar,
  }))(LayoutPartner)
);
