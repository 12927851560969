import React, { PureComponent } from "react";
import { Card, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import SegmentSelect from "./segmentSelect";
import { Input } from "antd";

class ApplyEdit extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeRegistrationNumber =
      this.onChangeRegistrationNumber.bind(this);
    this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onChangeDepartment = this.onChangeDepartment.bind(this);
    this.onChangePosition = this.onChangePosition.bind(this);
    this.onChangeStatusChange = this.onChangeStatusChange.bind(this);
    this.onChangeEmpStatus = this.onChangeEmpStatus.bind(this);
    this.onChangeRejectDesc = this.onChangeRejectDesc.bind(this);
    this.onChangeWorkStart = this.onChangeWorkStart.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      last_name: "",
      first_name: "",
      registration_number: "",
      phone_number: "",
      email: "",
      company_name: "",
      department: "",
      position: "",
      merchant_id: "",
      merchant_name: "",
      work_started_at: "",
      errors: {},
      departments: [],
      department: "",
      employee_status: "",
      emp_status_change_date: "",
      verified_date: "",
      reject_description: "",
      created_at: "",
    };
  }
  componentWillMount() {
    axios
      .get("https://merchantloyalty.upoint.mn/backend/api/segment/company")
      .then((response) => {
        console.log(response.data, "default");
        this.setState({
          merchant_id: response.data.merchant._id,
          merchant_name: response.data.merchant.name_mn,
          departments: response.data.company,
        });
        console.log(response.data, "data");
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  componentDidMount() {
    axios
      .get(
        "https://merchantloyalty.upoint.mn/backend/api/employee/" +
          this.props.match.params.id
      )
      .then((response) => {
        console.log(response.data, "state");

        this.setState({
          last_name: response.data.last_name,
          first_name: response.data.first_name,
          registration_number: response.data.registration_number,
          phone_number: response.data.phone_number,
          email: response.data.email,
          merchant_id: response.data.merchant_id,
          department: response.data.department,
          position: response.data.position,
          employee_status: response.data.employee_status,
          emp_status_change_date: response.data.emp_status_change_date,
          work_started_at: response.data.work_started_at,
          verified_date: response.data.verified_date,
          reject_description: response.data.reject_description,
          created_at: response.data.created_at,
        });
        console.log(this.state, "newstate");
      });
  }
  onChangeLastName(e) {
    this.setState({
      last_name: e.target.value,
    });
    console.log(this.state.department, "jinhene dep");
  }

  onChangeFirstName(e) {
    this.setState({
      first_name: e.target.value,
    });
  }
  onChangeRejectDesc(e) {
    this.setState({
      reject_description: e.target.value,
    });
  }
  onChangeRegistrationNumber(e) {
    this.setState({
      registration_number: e.target.value,
    });
  }
  onChangePhoneNumber(e) {
    this.setState({
      phone_number: e.target.value,
    });
  }
  onChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangeCompanyName(e) {
    this.setState({
      merchant_id: e.target.value,
    });
  }
  onChangeEmpStatus(e) {
    this.setState({
      employee_status: e.target.value,
    });
  }
  onChangeDepartment(e) {
    this.setState({
      department: e.target.value,
    });
  }

  onChangePosition(e) {
    this.setState({
      position: e.target.value,
    });
  }

  onChangeWorkStart(e) {
    this.setState({ work_started_at: e.target.value });
  }
  validate() {
    let last_name = this.state.last_name;
    let first_name = this.state.first_name;
    let registration_number = this.state.registration_number;
    let phone_number = this.state.phone_number;
    let email = this.state.email;
    let work_started_at = this.state.work_started_at;

    let errors = {};

    let isValid = true;

    if (!work_started_at) {
      isValid = false;

      errors["work_started_at"] = "Заавал утга оруулна уу";
    }
    if (!registration_number) {
      isValid = false;

      errors["registration_number"] = "Регистрийн дугаараа оруулна уу";
    } else {
      if (typeof registration_number !== "undefined") {
        var pattern = new RegExp(/^[А-ЯӨҮЁ]{2}(\d){8}$/gmu);

        if (!pattern.test(registration_number)) {
          isValid = false;

          errors["registration_number"] = "Регистрийн дугаараа зөв оруулна уу";
        }
      }
    }
    if (!last_name) {
      isValid = false;

      errors["last_name"] = "Овгоо оруулна уу";
    } else {
      if (typeof last_name !== "undefined") {
        var pattern = new RegExp(/^[А-Яа-яӨөҮүЁё-]{1,}$/gmu);

        if (!pattern.test(last_name)) {
          isValid = false;

          errors["last_name"] = "Кириллээр бичнэ үү";
        }
      }
    }
    if (!first_name) {
      isValid = false;

      errors["first_name"] = "Нэрээ оруулна уу";
    } else {
      if (typeof first_name !== "undefined") {
        var pattern = new RegExp(/^[А-Яа-яӨөҮүЁё-]{1,}$/gmu);

        if (!pattern.test(first_name)) {
          isValid = false;

          errors["first_name"] = "Кириллээр бичнэ үү";
        }
      }
    }
    if (!phone_number) {
      isValid = false;

      errors["phone_number"] = "Утасны дугаар оруулна уу";
    } else {
      if (typeof phone_number !== "undefined") {
        var pattern = new RegExp(/^(\+\d{3}(-){0,1}){0,1}([8-9]\d{7})$/gmu);

        if (!pattern.test(phone_number)) {
          isValid = false;

          errors["phone_number"] = "Утасны дугаараа зөв оруулна уу";
        }
      }
    }

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        isValid = false;

        errors["email"] = "И-мэйл хаягаа зөв оруулна уу";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }
  todoList() {
    return this.state.departments.map(function (currentTodo, i) {
      return <SegmentSelect departments={currentTodo} key={i} />;
    });
  }
  onChangeStatusChange(e) {
    this.setState({ emp_status_change_date: e.target.value });
  }

  // onSubmit(e) {
  //   e.preventDefault();

  //   const obj = {
  //     last_name: this.state.last_name,
  //     first_name: this.state.first_name,
  //     registration_number: this.state.registration_number,
  //     phone_number: this.state.phone_number,
  //     email: this.state.email,
  //     merchant_id: this.state.merchant_id,
  //     department: this.state.department,
  //     position: this.state.position,
  //     emp_status_change_date: this.state.emp_status_change_date,
  //     employee_status: this.state.employee_status,
  //     work_started_at: this.state.work_started_at,
  //     reject_description: this.state.reject_description
  //   };
  //   if (this.validate()) {
  //     axios
  //       .post(
  //         "https://merchantloyalty.upoint.mn/backend/api/employee/update/" +
  //         this.props.match.params.id,
  //         obj
  //       )
  //       .then((res) => {
  //         NotificationManager.success(res.data.message, "", 5000);
  //         console.log(res.data);
  //       })
  //       .catch((err) => {
  //         // console.log(err.response.data.message);
  //         // NotificationManager.error(error.message, "", 5000);
  //       });
  //     const rowsToKeep = [];
  //     rowsToKeep.push(this.props.match.params.id);
  //     axios
  //       .post("https://merchantloyalty.upoint.mn/backend/api/employee/apply_edit/", rowsToKeep)
  //       .then((res) => {
  //         this.props.history.push("/partner/signed_employee");
  //         NotificationManager.success(res.data.message, "", 5000);
  //       })
  //       .catch((error) => {
  //         NotificationManager.error(error.message, "", 5000);
  //         console.log(error);
  //       });
  //   }
  // }

  render() {
    const { TextArea } = Input;

    return (
      <Col md={12} lg={12}>
        <NotificationContainer style={{ position: "absolute" }} />
        <Card className="card-zasah">
          <CardBody>
            <CardTitle className="card__title">
              Ажилтны дэлгэрэнгүй мэдээлэл
            </CardTitle>
            <hr className="line" />
            <div style={{ marginTop: 20 }}>
              <form className="form form--horizontal" onSubmit={this.onSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Овог</span>
                  <div className="form__form-group-field">
                    <input
                      name="last_name"
                      component="input"
                      type="text"
                      disabled
                      placeholder="Овог"
                      value={this.state.last_name}
                      onChange={this.onChangeLastName}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.last_name}
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Нэр</span>
                  <div className="form__form-group-field">
                    <input
                      name="first_name"
                      component="input"
                      disabled
                      type="text"
                      placeholder="Нэр"
                      value={this.state.first_name}
                      onChange={this.onChangeFirstName}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.first_name}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Регистрийн дугаар
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="registration_number"
                      disabled
                      component="input"
                      type="text"
                      placeholder="Регистрийн дугаар"
                      value={this.state.registration_number}
                      onChange={this.onChangeRegistrationNumber}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.registration_number}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Утасны дугаар</span>
                  <div className="form__form-group-field">
                    <input
                      name="phone_number"
                      component="input"
                      type="text"
                      disabled
                      placeholder="Утасны дугаар"
                      value={this.state.phone_number}
                      onChange={this.onChangePhoneNumber}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.phone_number}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">И-мэйл хаяг</span>
                  <div className="form__form-group-field">
                    <input
                      name="email"
                      type="text"
                      disabled
                      placeholder="И-мэйл хаяг"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.email}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Ажилтны статус</span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.employee_status}
                      onChange={this.onChangeEmpStatus}
                      placeholder="Ажилтны статус"
                      required
                      disabled
                    >
                      <option value="" selected disabled>
                        Ажилтны статус сонгоно уу
                      </option>
                      <option value="1">Ажиллаж байгаа</option>
                      <option value="2">Хүүхэд асрах чөлөөтэй</option>
                      <option value="3">Чөлөөтэй</option>
                      <option value="4">Гэрээт</option>
                    </select>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Статус солигдсон огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="status_change_date"
                      component="input"
                      type="date"
                      disabled
                      placeholder="Ажилд орсон огноо"
                      value={moment(this.state.emp_status_change_date).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={this.onChangeStatusChange}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Газар, хэлтэс</span>
                  <div className="form__form-group-field">
                    <select
                      value={this.state.department}
                      onChange={this.onChangeDepartment}
                      disabled
                    >
                      {this.todoList()}
                    </select>
                  </div>
                </div>

                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.department}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Албан тушаал</span>
                  <div className="form__form-group-field">
                    <input
                      name="position"
                      component="input"
                      disabled
                      type="text"
                      placeholder="Албан тушаал"
                      value={this.state.position}
                      onChange={this.onChangePosition}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Ажилд орсон огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="work_started_at"
                      component="input"
                      type="date"
                      disabled
                      placeholder="Ажилд орсон огноо"
                      value={moment(this.state.work_started_at).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={this.onChangeWorkStart}
                    />
                  </div>
                </div>
                <div className="text-danger" style={{ marginLeft: "220px" }}>
                  {this.state.errors.work_started_at}
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Баталгаажсан огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="work_started_at"
                      component="input"
                      type="date"
                      disabled
                      placeholder="Баталгаажсан огноо"
                      value={moment(this.state.verified_date).format(
                        "YYYY-MM-DD"
                      )}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Бүртгэсэн огноо
                  </span>
                  <div className="form__form-group-field">
                    <input
                      name="work_started_at"
                      component="input"
                      type="date"
                      disabled
                      placeholder="Бүртгэсэн огноо"
                      value={moment(this.state.created_at).format("YYYY-MM-DD")}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Тайлбар</span>
                  <div className="form__form-group-field">
                    <TextArea
                      rows={4}
                      value={this.state.reject_description}
                      onChange={this.onChangeRejectDesc}
                      disabled
                    />
                  </div>
                </div>
                <br />
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default ApplyEdit;
